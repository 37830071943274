import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { Search } from 'lucide-react';
import { getLocation, fetchJobs, fetchSources, getJobs, getKeywords } from '../services/api';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authProvider';

const blurJobCount5 = parseInt(process.env.REACT_APP_BLUR_JOB_5, 10) || 5;
const blurJobCount10 = parseInt(process.env.REACT_APP_BLUR_JOB_10, 10) || 10;


const JobSearchPage = ({jobs: initialJobs, updateJobs}) => {
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [location, setLocation] = useState([]);
  const [position, setPosition] = useState([]);
  const [searchLocations, setSearchLocations] = useState([]);
  const [searchPosition, setsearchPosition] = useState('');
  const [sources, setSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState('');
  const [isPlus, setIsPlus] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [jobs, setJobs] = useState(() => initialJobs);
  const { isLoggedIn, isPlusUser } = useAuth();
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [blurTimeoutId, setBlurTimeoutId] = useState(null);

  useEffect(() => {
    setJobs(initialJobs);
    if (isPlusUser) {
      setIsPlus(true);
    }
  }, [initialJobs]);

  const observer = useRef();
  const lastJobElementRef = useRef();

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };
  
    window.addEventListener('scroll', toggleVisibility);
  
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };


  const handleSearch = async (term=searchTerm) => {
    setPage(1); 
    setHasMore(true); 
    setLoading(true); 
    try {
      const fetchedJobs = await fetchJobs(term, searchLocations.join(','), searchPosition, selectedSource, 1); 
      setJobs(fetchedJobs);
      updateJobs(fetchedJobs);
    } catch (error) {
      console.error('Error searching jobs:', error);
    } finally {
      setLoading(false);
    }
  }

  const handleTagClick = async (type, tag) => {
    setPage(1); 
    setHasMore(true); 
    setLoading(true); 
    if (type === 'Location') {
      setSearchLocations(prevLocations => 
        prevLocations.includes(tag)
          ? prevLocations.filter(loc => loc !== tag)
          : [...prevLocations, tag]
      );
      const updatedLocations = searchLocations.includes(tag)
        ? searchLocations.filter(loc => loc !== tag)
        : [...searchLocations, tag];
      const fetchedJobs = await fetchJobs(searchTerm, updatedLocations.join(','), searchPosition, selectedSource, 1); 
      setJobs(fetchedJobs);
      updateJobs(fetchedJobs);
      if (searchPosition) {
        navigate(`/job/${searchPosition}`);
      }
    } else if (type === 'Position') {
      try {
        let jobPosition = searchPosition === tag ? '' : tag;
        setsearchPosition(jobPosition);
        const positionJobs = await fetchJobs(searchTerm, searchLocations.join(','), jobPosition, selectedSource, 1);
        setJobs(positionJobs);
        updateJobs(positionJobs);
        if (jobPosition) {
          navigate(`/job/${jobPosition}`);
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error("Error fetching jobs by position:", error);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    const fetchInitialData = async () => {
      const jobsData = await getJobs();
      setJobs(jobsData);
    };
  
    fetchInitialData();
  }, []);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const result = await getLocation();
        setLocation(result);
      } catch (error) {
        console.error("Error fetching locations:", error);
        setLocation([]);
      }
    }
    fetchLocations();
  }, []);

  const positions = [
    "Python","Full Stack","Backend","Front End", "Javascript","React", "JAVA","Data Science","AI","UI","Machine Learning","PHP","NODE",
    "IOS","Android","SEO","RUST","Blockchain", "Ethereum", "DEFI", "NFT", "Solidity","Typescript","Golang","Solana","Layer 2","Marketing", 
    "RUBY","VUE","SAAS"
  ];
  
  useEffect(() => {
    const fetchPositions = async () => {
      try {
        setPosition(positions);
      } catch (error) {
        console.error("Error fetching positions:", error);
        setPosition([]);
      }
    }
    fetchPositions();
  }, []);

  useEffect(() => {
    const fetchSourcesList = async () => {
      const sourceData = await fetchSources();
      setSources(sourceData);
    };
    fetchSourcesList();
  }, []);

  const handleSourceChange = (source) => {
    try {
      const sourceSearch = async () => {
        setSelectedSource(source);
        const sourceSearchJobs = await fetchJobs(searchTerm, searchLocations.join(','), searchPosition, source, 1);
        setJobs(sourceSearchJobs);
        updateJobs(sourceSearchJobs);
      }
      sourceSearch();
    } catch (error) {
      console.error("Error in handleSourceChange:", error);
    }
  };

  const loadMoreJobs = async () => {
    if (!isPlus || !isLoggedIn) return;
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const newJobs = await fetchJobs(searchTerm, searchLocations.join(','), searchPosition, selectedSource, page + 1);
      if (newJobs.length === 0) {
        setHasMore(false);
      } else {
        setJobs(prevJobs => [...prevJobs, ...newJobs]);
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      console.error('Error loading more jobs:', error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (loading) return;
  //   if (observer.current) observer.current.disconnect();
  //   observer.current = new IntersectionObserver(entries => {
  //     console.log("entries: ", entries);
  //     if (entries[0].isIntersecting && hasMore) {
  //       loadMoreJobs();
  //     }
  //   });
  //   if (lastJobElementRef.current) {
  //     console.log('Observing last job element:', lastJobElementRef.current);
  //     observer.current.observe(lastJobElementRef.current);
  //   }
  // }, [loading, hasMore, jobs.length]);

  // useEffect(() => {
  //   const checkPlusStatus = async () => {
  //     const isPlusUser = localStorage.getItem('isPlusUser');
  //     if (isPlusUser) {
  //       setIsPlus(true);
  //     }
  //   };
  //   checkPlusStatus();
  // }, [jobs]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const fetchSuggestions = async (input) => {
    try {
      const recommendKeywords = await getKeywords(input);
      setSuggestions(recommendKeywords);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]);
    }
  };

  useEffect(() => {
    if (showSuggestions) {
      fetchSuggestions(searchTerm);
    } else {
      setSuggestions([]);
    }
  }, [searchTerm, showSuggestions]);

  useEffect(() => {
    return () => {
      if (blurTimeoutId) {
        clearTimeout(blurTimeoutId);
      }
    };
  }, [blurTimeoutId]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchTerm(newValue);
    setShowSuggestions(true);
  };

  return (
    <div className="min-h-screen p-8">
      <div className='flex justify-center text-center text-2xl font-bold pt-16'>One Platform, All Remote Jobs</div>
      <div className="flex justify-center">
        <div className="relative flex items-center bg-white rounded-full shadow-lg p-2 mb-4 w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl">
          <input
            type="text"
            placeholder="Search..."
            className="flex-grow px-4 py-2 focus:outline-none w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl"
            value={searchTerm}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            onFocus={() => setShowSuggestions(true)}
            onBlur={() => {
              const timeoutId = setTimeout(() => setShowSuggestions(false), 200);
              setBlurTimeoutId(timeoutId);
            }}
          />
          <button 
            className="bg-blue-500 text-white p-2 rounded-full" 
            onClick={(e) => {
              e.preventDefault(); // 防止可能的表单提交
              handleSearch();
            }}
          >
            <Search size={20} />
          </button>
          {showSuggestions && suggestions.length > 0 && (
            <ul className="absolute z-10 w-full bg-white mt-1 rounded-md shadow-lg max-h-60 overflow-auto top-full left-0 text-left pl-2">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onMouseDown={(e) => {
                    e.preventDefault(); // 防止触发输入框的 blur 事件
                  }}
                  onClick={() => {
                    clearTimeout(blurTimeoutId);
                    const newSearchTerm = suggestion.split(' (')[0];
                    setSearchTerm(newSearchTerm);
                    handleSearch(newSearchTerm);
                  }}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div className='centered-container pt-8'>
        <div className="mb-8">
          <div className="flex flex-wrap gap-2">
            <TagSection 
              title="Position" 
              tags={position} 
              selectedTags={[searchPosition]}
              onTagClick={(tag) => handleTagClick('Position', tag)}
              multiSelect={false}
            />
            <TagSection 
              title="Location" 
              tags={location} 
              selectedTags={searchLocations}
              onTagClick={(tag) => handleTagClick('Location', tag)}
              multiSelect={true}
            />
          </div>
        </div>
        {loading && <p className="text-center">Loading...</p>}
        <div className="mb-4">
          <SourceDropdown
            sources={sources}
            selectedSource={selectedSource}
            onSourceChange={handleSourceChange}
          />
        </div>
        <div className="mb-4">
          <div className="flex flex-wrap gap-2">
            {searchLocations.map((loc) => (
              <span key={`loc-${loc}`} className="bg-blue-200 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                {loc} <button onClick={() => handleTagClick('Location', loc)} className="ml-1 text-blue-500">&times;</button>
              </span>
            ))}
            {searchPosition && (
              <span key={`pos-${searchPosition}`} className="bg-green-100 text-green-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                {searchPosition} <button onClick={() => handleTagClick('Position', '')} className="ml-1 text-green-500">&times;</button>
              </span>
            )}
          </div>
        </div>
        
        <div className="space-y-4">
          {jobs.length > 0 ? jobs.map((job, index) => (
            <div
              key={job.id}
              ref={index === jobs.length - 1 ? lastJobElementRef : null}
            >
              <JobCard 
                index={index}
                job={job} 
                isLoggedIn={isLoggedIn}
                isPlus={isPlus}
                blurred={isLoggedIn ? index >= blurJobCount10 : index >= blurJobCount5} 
              />
            </div>
          )) : <div className="text-center"><p className="text-center">No jobs found</p><p className="text-center">Please try another search</p></div>}
          {loading && <p className="text-center">Loading...</p>}
          {!hasMore && <p className="text-center">No more jobs</p>}
        </div>
        {jobs.length > 0 && isPlus && (
          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mt-2 sm:mt-0" onClick={loadMoreJobs}>Load More</button>
        )}
        {showScrollTop && (
          <button
            onClick={scrollToTop}
            className={`fixed right-8 bg-blue-500 text-white p-2 rounded-full shadow-lg hover:bg-blue-600 transition-colors duration-300 z-50
                ${hasMore ? 'bottom-8' : 'bottom-[200px]'}
              `}
            aria-label="Scroll to top"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
            </svg>
          </button>
        )}
      </div>
      {!hasMore && <div className="h-16"></div>}
    </div>
  );
};


const SourceDropdown = ({ sources, selectedSource, onSourceChange }) => {
  return (
    <div className="mb-4">
      <select
        id="source-select"
        value={selectedSource}
        onChange={(e) => onSourceChange(e.target.value)}
        className="mt-1 block w-28 pl-1 pr-1 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      >
        <option value="">All Sources</option>
        {sources.map((source) => (
          <option key={source} value={source} className='text-left'>
            {source}
          </option>
        ))}
      </select>
    </div>
  );
};


const TagSection = ({ title, tags, selectedTags, onTagClick, multiSelect }) => {
  const [showAll, setShowAll] = useState(false);
  const initialDisplayCount = 25;

  const visibleTags = showAll ? tags : tags.slice(0, initialDisplayCount);

  return (
    <div className="mb-4">
      <h2 className="text-sm font-semibold mb-2 text-left">{title}</h2>
      <div className="flex flex-wrap gap-2">
        {visibleTags.map((tag, index) => (
          <button 
            key={index} 
            className={`px-3 py-1 rounded-full text-sm transition-colors ${
              multiSelect
                ? selectedTags.includes(tag)
                  ? 'bg-blue-500 text-white'
                  : 'bg-white hover:bg-gray-100'
                : selectedTags[0] === tag
                  ? 'bg-blue-500 text-white'
                  : 'bg-white hover:bg-gray-100'
            }`}
            onClick={() => onTagClick(tag)}
          >
            {tag}
          </button>
        ))}
        {tags.length > initialDisplayCount && (
          <button 
            className="px-3 py-1 rounded-full text-sm bg-gray-200 hover:bg-gray-300 transition-colors"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? 'Less' : 'More'}
          </button>
        )}
      </div>
    </div>
  );
};


const JobCard = ({ index, job, blurred, isLoggedIn, isPlus }) => {
  const navigate = useNavigate();
  blurred = isPlus ? false : blurred;

  const handleCardClick = (e) => {
    if (!blurred) {
      // 防止默认行为
      e.preventDefault();
      // 在新标签页中打开链接
      window.open(`/job/remote/${job.uid}`, '_blank');
    }
  };

  const firstBlurIndex = isLoggedIn ? blurJobCount10 : blurJobCount5;

  return (
    <div className="relative">
      <div 
        className={`bg-white rounded-lg p-4 mb-4 ${blurred ? 'blur-sm' : 'cursor-pointer'}`}
        onClick={handleCardClick}
      >
        <div className="flex justify-between items-center mb-3">
          <h3 className="font-bold text-lg text-left">{job.title}</h3>
          <div className='flex gap-2 mt-2 rounded text-xs flex-wrap'>
            {job.country && <span className="text-gray-500 bg-gray-100 rounded-full px-2 py-0.5 text-xs">{job.country}</span>}
            {job.publish_date && <span className="text-gray-500 text-xs">Posted: {new Date(job.publish_date).toLocaleDateString()}</span>}
          </div>
          
        </div>
        <p className="text-gray-600 text-left mb-3">{job.description}</p>
        
        <div className="flex justify-between items-center mt-2">
          <div className='flex gap-2 mt-2 rounded text-xs flex-wrap'>
            {job.source && <span className="text-blue-500">{job.source}</span>}
            {job.tags.map((tag, index) => (
              <span key={index} className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                {tag}
              </span>
            ))}
            {job.max_salary > 0 && <span className="text-gray-600">${job.min_salary} ~ ${job.max_salary}</span>}
          </div>
          <button 
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mt-2 sm:mt-0"
            onClick={(e) => {
              e.stopPropagation();
              // Handle apply button click
              window.open(job.apply_url, '_blank');
            }}
          >
            Apply
          </button>
        </div>
      </div>
      
      {blurred && index === firstBlurIndex && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 rounded-lg">
          <div className="text-black text-center p-4">
            {!isLoggedIn ? (
              <>
                <p className="text-lg font-bold mb-2">Login to view more content</p>
                <button 
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  onClick={() => navigate('/login')}
                >
                  Login
                </button>
              </>
            ) : !isPlus ? (
              <>
                <p className="text-lg font-bold mb-2">Upgrade to Plus to unlock all content</p>
                <button 
                  className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
                  onClick={() => navigate('/plan')}
                >
                  Upgrade
                </button>
              </>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};


export default JobSearchPage;