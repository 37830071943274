import React from 'react';
import { Link } from 'react-router-dom';
import { getJobs } from '../services/api';
import { useNavigate } from 'react-router-dom';


export default function UnderConstruction({setJobs}) {
  const navigate = useNavigate();
  const fetchJobs = async () => {
    try {
      const jobs = await getJobs();
      setJobs(jobs);
      navigate('/');
    } catch (error) {
      console.error('Failed to fetch jobs:', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-4 text-center">
      <h1 className="text-4xl font-bold text-gray-800 mb-4">🚧 Page Under Construction 🚧</h1>
      <p className="text-xl text-gray-600 mb-8">We are working hard to provide you with a better experience, please come back later.</p>
      <div className="w-24 h-24 border-t-4 border-blue-500 border-solid rounded-full animate-spin mb-8"></div>
      <Link 
        to="/" 
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
        onClick={fetchJobs}
      >
        Home Page
      </Link>
    </div>
  );
}
