import React from 'react';
import { Bug, Twitter, Mail } from 'lucide-react'
import { useNavigate } from 'react-router-dom';
const Footer = () => {
    const navigate = useNavigate();

    return (
    <footer className="bg-gray-800 text-white py-4 w-full">
    <div className="container mx-auto px-4">
        <div className='flex flex-row justify-between'>
            <h3 className="text-lg font-semibold mb-2 text-left cursor-pointer hover:text-blue-400"
            onClick={() => {
                navigate('/dashboard');
            }}
            >
                One Platform, All Remote Jobs</h3>
            <div className='flex flex-row'>
                <h3 className="text-sm font-semibold mb-4 mr-4"></h3>
                <div className="flex space-x-1">
                    <Mail className="text-white hover:text-blue-400 mr-2 size-5 cursor-pointer"  onClick={() => {
                        window.open('mailto:remotesum@gmail.com', '_blank');
                    }}/>
                    <Twitter className="text-white hover:text-blue-400 mr-2 size-5 cursor-pointer"  onClick={() => {
                        window.open('https://x.com/RemotesumTabman', '_blank');
                    }}/>
                    <a href="#" className="text-white hover:text-blue-400 cursor-pointer group relative">
                        <Bug className="size-5"  onClick={() => {
                            window.open('https://github.com/snjyor', '_blank');
                        }}/>
                        <span className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 text-white text-center rounded-md py-1 px-2 text-xs whitespace-nowrap">
                            Feedback
                        <span className="absolute top-full left-1/2 transform -translate-x-1/2 border-4 border-transparent border-t-black"></span>
                        </span>
                    </a>
                </div>
            </div>
        </div>
        <div className="flex flex-row justify-center">
            <div>
                <p>&copy; {new Date().getFullYear()} RemoteSum. All rights reserved.</p>
            </div>
        </div>
    </div>
    </footer>
    );
};

export default Footer;
