import React, { createContext, useState, useContext, useEffect } from 'react';
import { isTokenExpired, checkPlusStatus, refreshTokenApi } from '../services/api';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPlusUser, setIsPlusUser] = useState(false);

  useEffect(() => {
    const checkLoginState = async () => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        const isExpired = await isTokenExpired(token);
        setIsLoggedIn(!isExpired);
      }
    };

    const checkPlusState = async () => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        const isPlusUser = await checkPlusStatus(user.id);
        setIsPlusUser(isPlusUser);
      }
    };

    const refreshToken = async () => {
      const token = localStorage.getItem('accessToken');
      const newToken = await refreshTokenApi(token);
      localStorage.setItem('accessToken', newToken);
    };

    checkLoginState();
    checkPlusState();
    // refreshToken();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, isPlusUser, setIsPlusUser }}>
      {children}
    </AuthContext.Provider>
  );
};

