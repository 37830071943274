import React from 'react';
import { createCheckoutSession } from '../services/api';


export default function PlanPage() {

  const pricingTiers = [
    {
      id: 1,
      title: 'Monthly Flexible Plan',
      price: process.env.REACT_APP_MONTHLY_PRICE,
      priceId: process.env.REACT_APP_MONTHLY_PRICE_ID,
      period: 'Month',
      features: [
        'Unlimited browsing of job details',
        'Advanced search and filtering features',
        'Daily latest job push notifications'
      ],
      isPopular: false,
      buttonText: 'Choose the monthly plan'
    },
    {
      id: 2,
      title: 'Quarterly Discount Plan',
      price: process.env.REACT_APP_QUARTERLY_PRICE,
      priceId: process.env.REACT_APP_QUARTERLY_PRICE_ID,
      period: 'Quarter',
      features: [
        'Unlimited browsing of job details',
        'Advanced search and filtering features',
        'Daily latest job push notifications'
      ],
      isPopular: false,
      buttonText: 'Choose the quarterly plan'
    },
    {
      id: 3,
      title: 'Annual Value Plan',
      price: process.env.REACT_APP_ANNUAL_PRICE,
      priceId: process.env.REACT_APP_ANNUAL_PRICE_ID,
      period: 'Year',
      features: [
        'Unlimited browsing of job details',
        'Advanced search and filtering features',
        'Daily latest job push notifications',
        'Exclusive annual industry report',
        'Extra 2 months'
      ],
      isPopular: true,
      buttonText: 'Choose the annual plan'
    }
  ];

  const handlePlanSelection = async (priceId, planTitle) => {
    try {
      const userToken = localStorage.getItem('accessToken');
      if (!userToken || userToken === 'undefined') {
        alert('Please login first');
        window.location.href = '/login';
        return;
      }
      const userEmail = JSON.parse(localStorage.getItem('user')).email;

      const response = await createCheckoutSession(userEmail, priceId, planTitle);
      const redirectUrl = response.session.url;
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        console.error('Failed to create checkout session');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  return (
      <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Pricing</h2>
            <p className="mt-2 text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">
              Choose the most suitable membership plan for you
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              Start your global remote work journey! We gather high-quality positions from multiple platforms to help you seize the opportunity in the international remote work market.
            </p>
          </div>
          <div className="mt-16 grid gap-8 lg:grid-cols-3 lg:gap-x-8">
            {pricingTiers.map((tier) => (
              <PricingTier 
                key={tier.title} 
                {...tier} 
                onSelect={() => handlePlanSelection(tier.priceId, tier.title)}
              />
            ))}
          </div>
          <div className="mt-10 text-center">
            <p className="text-lg text-gray-600">
              Choose the annual plan to enjoy the highest discount! Save more than ${Math.round(process.env.REACT_APP_MONTHLY_PRICE * 12 - process.env.REACT_APP_ANNUAL_PRICE)} and get uninterrupted high-quality services and exclusive industry insights throughout the year.
            </p>
            <p className="mt-4 text-sm text-gray-500">
              Subscribe now to get more high-quality remote work opportunities!
            </p>
          </div>
        </div>
      </div>
  );
}


const PricingTier = ({title, price, priceId, period, features, isPopular, buttonText, onSelect }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md flex flex-col h-full">
      {isPopular && (
        <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded-full self-start mb-2">
          Best choice
        </span>
      )}
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className="text-gray-600 mb-3 mt-2">
        {title === 'Monthly Flexible Plan' 
          ? 'Flexible choice, ready to start'
          : title === 'Quarterly Discount Plan'
          ? `Save money and enjoy a ${Math.round(((process.env.REACT_APP_MONTHLY_PRICE*3)-process.env.REACT_APP_QUARTERLY_PRICE)/(process.env.REACT_APP_MONTHLY_PRICE*3)*100)}% discount`
          : `Save money and enjoy a ${Math.round(((process.env.REACT_APP_MONTHLY_PRICE*12)-process.env.REACT_APP_ANNUAL_PRICE)/(process.env.REACT_APP_MONTHLY_PRICE*12)*100)}% discount`}
      </p>
      <p className="text-gray-600 mb-4">
        {title === 'Monthly Flexible Plan' 
          ? 'Suitable for short-term trials or specific project needs'
          : title === 'Quarterly Discount Plan'
          ? `Help your career development for three months, moving forward steadily`
          : `Enjoy a ${Math.round(((process.env.REACT_APP_MONTHLY_PRICE*12)-process.env.REACT_APP_ANNUAL_PRICE)/(process.env.REACT_APP_MONTHLY_PRICE*12)*100)}% discount, equivalent to $${Math.round((process.env.REACT_APP_MONTHLY_PRICE*12-process.env.REACT_APP_ANNUAL_PRICE)/12)}/month, providing uninterrupted global career opportunities throughout the year`}
      </p>
      <div className="text-3xl font-bold mb-4">
        ${price}<span className="text-base font-normal text-gray-600">/{period}</span>
      </div>
      <ul className="mb-6 flex-grow">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center mb-2">
            <svg className="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
            {feature}
          </li>
        ))}
      </ul>
      <button 
        className={`w-full py-2 px-4 rounded ${
          isPopular 
            ? 'bg-blue-600 text-white hover:bg-blue-700' 
            : 'bg-white text-blue-600 border border-blue-600 hover:bg-blue-50'
        }`}
        onClick={onSelect}
      >
        {buttonText}
      </button>
    </div>
  )
}

