import React, { useState, useEffect } from 'react';
import { FaSearch, FaDatabase, FaBolt, FaCode, FaPython, FaReact, FaHeadset, FaChevronDown, FaLinode } from 'react-icons/fa';
import backgroundImage from '../imgs/green-ink-streams-on-white.webp';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authProvider';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import { VscCopilot, VscLayers,  } from "react-icons/vsc";
import { getKeywordsForIndex } from '../services/api';


const CustomBar = (props) => {
  const { x, y, width, height } = props;
  const radius = width / 2;

  return (
    <g>
      <rect x={x} y={y} width={width} height={height} fill="#60a5fa" /> {/* light blue */}
      <ellipse cx={x + width / 2} cy={y} rx={radius} ry={radius / 1.2} fill="#60a5fa" />
    </g>
  );
};

const SkillChart = () => {
  const [skillData, setSkillData] = useState([]);

  useEffect(() => {
    const fetchSkillData = async () => {
      try {
        const response = await getKeywordsForIndex();
        setSkillData(response);
      } catch (error) {
        console.error('获取技能数据时出错:', error);
      }
    };

    fetchSkillData();
  }, []);

  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart data={skillData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="skill" angle={45} textAnchor="start" height={100} interval={0} />
        <YAxis />
        <Tooltip />
        <Bar dataKey="counts" shape={<CustomBar />} />
      </BarChart>
    </ResponsiveContainer>
  );
};

const LandingPage = () => {
  const navigate = useNavigate();
  const { isLoggedIn, isPlusUser } = useAuth();

  return (
    <div className="landing-page font-sans min-h-screen flex flex-col">
      {/* heroarea */}
      <div className="flex items-center justify-center mt-[150px]">
        <div className="container mx-auto text-center relative z-10 text-gray-800 px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 md:mb-12">One-Stop Remote Job Search Platform</h1>
          <p className="text-xl md:text-2xl mb-8 md:mb-20">Aggregating dozens of top remote job platforms to provide you with the most comprehensive and convenient remote work opportunities</p>
          <div className="flex justify-center">
          <button className="bg-blue-500 text-white px-8 md:px-12 py-3 md:py-4 rounded-lg hover:bg-blue-600 transition duration-300 text-lg md:text-xl font-semibold"
            onClick={() => navigate('/')}
          >
            Start Exploring
          </button>
          </div>
        </div>
      </div>

      <div className="landing-page font-sans min-h-screen flex flex-col mt-[130px]" >
        {/* Core Advantages */}
        <div className="py-24 relative">
          <div className="container mx-auto px-4 relative z-10">
            <h2 className="text-4xl font-bold mb-16 text-center">Why Choose Us</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mb-12">
              <FeatureCard
                icon={<FaSearch className="text-6xl text-blue-500" />}
                title="Convenient Search"
                description="Powerful search functionality to easily find your dream job"
              />
              <FeatureCard
                icon={<FaDatabase className="text-6xl text-blue-500" />}
                title="Data Comprehensive"
                description="Aggregating dozens of top remote job platforms to provide you with the most comprehensive and convenient remote work opportunities"
              />
              <FeatureCard
                icon={<FaBolt className="text-6xl text-blue-500" />}
                title="Real-Time Updates"
                description="Job information is updated in real-time, so you don't miss any opportunities"
              />
            </div>
          </div>
        </div>

        {/* Data Statistics */}
        <div className="py-24">
          <div className="container mx-auto text-center px-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              <StatItem number="100,000+" text="Remote Job Opportunities" />
              <StatItem number="50+" text="Integrated Job Platforms" />
              <StatItem number="100+" text="Coverage of Countries" />
            </div>
          </div>
        </div>

        {/* Popular Remote Work Categories */}
        <div className="py-24 relative overflow-hidden">
          <div className="container mx-auto px-4 relative z-10">
            <h2 className="text-4xl font-bold mb-16 text-center">Popular Remote Work Categories</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6 justify-items-center">
              <CategoryButton icon={<FaCode />} text="Full Stack Development" />
              <CategoryButton icon={<VscLayers />} text="WEB3" />
              <CategoryButton icon={<VscCopilot />} text="AI" />
              <CategoryButton icon={<FaLinode />} text="Blockchain" />
              <CategoryButton icon={<FaPython />} text="Python" />
              <CategoryButton icon={<FaHeadset />} text="Operations" />
              <CategoryButton icon={<FaDatabase />} text="Backend Development" />
              <CategoryButton icon={<FaReact />} text="Frontend Development" />
            </div>
          </div>
        </div>

        {/* Popular Remote Work Directions */}
        <div className="py-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-col items-center">
              <h2 className="text-4xl font-bold mb-8 text-center">Popular Remote Work Directions</h2>
              <div className="w-full">
                <SkillChart />
              </div>
            </div>
          </div>
        </div>

        {/* Platform Partners */}
        <div className="py-24">
          <div className="container mx-auto text-center px-4">
            <h2 className="text-4xl font-bold mb-16">Partners</h2>
            <div className="grid grid-cols-2 md:grid-cols-6 gap-8">
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <div key={i} className="bg-white p-8 rounded-lg shadow-lg flex items-center justify-center">
                  <span className="text-2xl font-bold text-gray-400">Logo {i}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* FAQ */}
        <FAQ />

        {/* CTA */}
        <div className="py-24">
          <div className="container mx-auto text-center px-4">
            <h2 className="text-4xl font-bold mb-8">Are you ready to start your remote work journey?</h2>
            <p className="text-xl mb-12">Explore now and get the latest remote job opportunities!</p>
            <button className="bg-blue-500 text-white px-12 py-4 rounded-lg hover:bg-blue-600 transition duration-300 text-xl font-semibold"
            onClick={() => navigate('/')}
          >
            Explore Now
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};


const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4">
      <button
        className={`w-full text-left p-4 bg-gray-800 text-white ${isOpen ? "rounded-t-lg" : "rounded-lg"} flex justify-between items-center`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{question}</span>
        <FaChevronDown className={`transition-transform duration-300 ${isOpen ? 'transform rotate-180' : ''}`} />
      </button>
      {isOpen && (
        <div className="p-4 bg-gray-700 text-white rounded-b-lg mt-0 text-left">
          {answer}
        </div>
      )}
    </div>
  );
};

const FAQ = () => {
  const faqData = [
    {
      question: "What are the advantages of this platform?",
      answer: "This platform gathers multiple remote job recruitment websites, providing a one-stop remote job search service. It is easy to use, with a unified style, allowing users to search for remote jobs without switching between multiple websites, saving time and improving efficiency."
    },
    {
      question: "How often are the job listings updated?",
      answer: "Our job listings are updated daily to ensure you see the latest opportunities. We maintain real-time data synchronization with multiple job websites and companies to provide the most current information."
    },
    {
      question: "Do I need to pay to use this platform?",
      answer: "Yes, to view all job listings, you need to pay. However, free users can also view the latest job listings daily, although they can only view 10 listings at a time. But you can view more job opportunities by using different search conditions, such as keywords, job types, company names, etc."
    },
    {
      question: "Can I post job listings on this platform?",
      answer: "This feature is under development. More features like job collection, personal information filling, and job recommendation are coming soon. Please stay tuned."
    },
    {
      question: "If I encounter technical issues, what should I do?",
      answer: "We provide 24/7 customer support. You can send an email to our support team for assistance. We promise to reply to all inquiries within 24 hours."
    }
  ];

  return (
    <div className="py-24">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-16 text-center">Frequently Asked Questions</h2>
        <div className="max-w-3xl mx-auto text-left">
          {faqData.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
        <p className="text-center mt-8 text-gray-400">
          For other questions, please 
          <a href="mailto:remotesum@gmail.com" className="text-blue-500 hover:text-blue-600 ml-1">
            contact us
          </a>
        </p>
      </div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="text-center p-8 bg-gradient-to-t from-transparent via-white/80 to-transparent rounded-lg shadow-lg transition duration-300 hover:shadow-xl">
    <div className="mb-6">{icon}</div>
    <h3 className="text-2xl font-semibold mb-4">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const FeatureCircle = ({ title, description }) => (
  <div className="text-center w-30 h-30 flex flex-col items-center justify-center bg-gray-50 rounded-full shadow-lg transition duration-300 hover:shadow-xl overflow-hidden">
    <h3 className="text-2xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600 text-sm px-4">{description}</p>
  </div>
);

const CategoryButton = ({ icon, text }) => (
  <div className="flex flex-col items-center justify-center w-40 h-40 bg-gradient-to-b from-transparent via-gray-100 to-green-100 rounded-full shadow-lg transition duration-300 hover:shadow-xl">
    <div className="mb-2">{icon}</div>
    <span className="text-2xl text-center">{text}</span>
  </div>
);

const SkillBar = ({ skill, percentage }) => (
  <div>
    <div className="flex justify-between mb-2">
      <span className="text-lg font-semibold">{skill}</span>
      <span className="text-lg font-semibold">{percentage}%</span>
    </div>
    <div className="w-full bg-gray-200 rounded-full h-4">
      <div className="bg-blue-600 h-4 rounded-full" style={{ width: `${percentage}%` }}></div>
    </div>
  </div>
);

const StatItem = ({ number, text }) => (
  <div>
    <h3 className="text-5xl font-bold mb-4">{number}</h3>
    <p className="text-xl">{text}</p>
  </div>
);

export default LandingPage;