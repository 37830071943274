import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDetail } from '../services/api';
import MarkdownRenderer from '../controllers/markdownController';


const JobDetailPage = () => {
    const [job, setJob] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { uid } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchJob = async () => {
            try {
                setIsLoading(true);
                const jobData = await getDetail(uid);
                if (!jobData) {
                    throw new Error('Job not found');
                }
                setJob(jobData);
            } catch (err) {
                console.error('Error in Get Detail:', err);
                setError(err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchJob();
    }, [uid]);

    useEffect(() => {
        if (error || (!isLoading && !job)) {
            console.error("Navigating to 404 page due to error or missing job data");
            navigate('/404', { replace: true });
        }
    }, [error, isLoading, job, navigate]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error || !job) {
        return <div>Error loading job details</div>;
    }


    return (
        <div className='p-10'>
            <h1 className='text-2xl font-bold pb-8'>{job.title}</h1>
            <div className='flex flex-col text-left text-sm'>
                {job.company_name ? (
                    <p className='text-sm pb-2'>
                        <span className='font-bold'>Company:</span> {job.company_name}
                    </p>
                ): null}
                {job.source ? (
                    <p className='text-sm pb-2'>
                        <span className='font-bold'>Source:</span> {job.source}
                    </p>
                ): null}
                {job.max_salary && job.min_salary ? (
                    <p className='text-sm pb-2'>
                        <span className='font-bold'>Salary:</span> ${job.min_salary} - ${job.max_salary}
                    </p>
                ) : null}
                {job.location ? (
                    <p className='text-sm pb-2'>
                        <span className='font-bold'>Location:</span> {job.location}
                    </p>
                ) : null}
                {job.tags ? (
                    <p className='text-sm pb-2'>
                        <span className='font-bold'>Skills:</span> {job.tags.map((tag, index) => (
                        <span key={index} className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded mr-2">
                            {tag}
                        </span>
                        ))}
                    </p>
                ) : null}
            </div>
            <div className='flex justify-end'>
                <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                    onClick={() => window.open(job.apply_url, '_blank')}
                >
                    Apply
                </button>
            </div>
            <MarkdownRenderer content={job.detail} />
            <div className='flex justify-center pt-10 flex-col'>
                <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                    onClick={() => window.open(job.apply_url, '_blank')}
                >
                    Apply
                </button>
                <p className='text-sm text-gray-500'>
                    Attention: By clicking the button you will be leaving RemoteSum and going to the platform where the job was posted. 
                    Please screen jobs carefully to avoid being scammed. RemoteSum assumes no responsibility or liability for job postings on external sites.
                </p>
            </div>
        </div>
    );
};

export default JobDetailPage;
