import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { login, logout, register, checkPlusStatus } from '../services/api';
import { useAuth } from './authProvider';

function LoginPage() {
  const [isLogin, setisLogin] = useState(true);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setIsLoggedIn, setIsPlusUser } = useAuth();


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    let response;
    if (isLogin) {
      try {
        response = await login(username, password);
        setisLogin(true);
        localStorage.setItem('accessToken', response.token);
        localStorage.setItem('user', JSON.stringify(response.user));
        setIsLoggedIn(true);
        window.location.href = '/';
      } catch (error) {
        setError(error.message);
        alert('Login failed: ' + error.message);
      }
    } else {
      try {
        response = await register(username, email, password);
        response = await login(username, password);
        setisLogin(true);
        localStorage.setItem('accessToken', response.token);
        localStorage.setItem('user', JSON.stringify(response.user));
        setIsLoggedIn(true);
        window.location.href = '/';
        alert('Register success');
      } catch (error) {
        setError(error.message);
        alert('Register failed: ' + error.message);
      }
    }
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleRegister = () => {
    navigate('/register');
  };

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      setisLogin(false);
      setIsLoggedIn(false);
      console.log("you are logout");
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const toggleForm = () => {
    setisLogin(!isLogin);
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className={`relative w-96 h-[400px]`}>
        <AnimatePresence initial={false} mode="wait">
          <motion.div 
            key={isLogin ? 'login' : 'register'}
            className="absolute w-full h-full bg-white p-8 rounded-lg shadow-md"
            initial={{ rotateY: -90, opacity: 0 }}
            animate={{ rotateY: 0, opacity: 1 }}
            exit={{ rotateY: 90, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <h2 className="text-2xl font-bold mb-6 text-center">
              {isLogin ? 'Login' : 'Register'}
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <input
                type="text"
                placeholder="Username/Email"
                onChange={(e) => setUsername(e.target.value)}
                required
                className="w-full px-3 py-2 border rounded-md"
              />
              {!isLogin && (
                <input
                  type="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full px-3 py-2 border rounded-md"
                />
              )}
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-3 py-2 border rounded-md"
              />
              {isLogin && (
                <div className="flex items-center justify-between">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                      className="mr-2"
                    />
                    <span>Remember me</span>
                  </label>
                  <a href="#" className="text-blue-500 hover:underline">
                    Forgot Password?
                  </a>
                </div>
              )}
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
              >
                {isLogin ? 'Login' : 'Register'}
              </button>
            </form>
            <p className="mt-4 text-center">
              {isLogin ? 'No account?' : 'Have an account?'}
              <button
                onClick={toggleForm}
                className="text-blue-500 hover:underline ml-1"
              >
                {isLogin ? 'Register' : 'Login'}
              </button>
            </p>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}

export default LoginPage;