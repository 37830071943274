import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkBreaks from 'remark-breaks';

const MarkdownRenderer = ({ content }) => {

    return (
        <div className="prose prose-lg max-w-none text-left">
        <ReactMarkdown 
            remarkPlugins={[remarkGfm, remarkBreaks]} 
            rehypePlugins={[rehypeRaw]}
            components={{
                h1: ({node, ...props}) => <h1 className="text-3xl font-bold mt-8 mb-4" {...props} />,
                h2: ({node, ...props}) => <h2 className="text-2xl font-semibold mt-6 mb-3" {...props} />,
                h3: ({node, ...props}) => <h3 className="text-xl font-medium mt-4 mb-2" {...props} />,
                p: ({node, ...props}) => <p className="mb-4" {...props} />,
                ul: ({node, ...props}) => <ul className="list-disc mb-4 pl-6" {...props} />,
                ol: ({node, ...props}) => <ol className="list-decimal mb-4 pl-6" {...props} />,
                li: ({node, ...props}) => <li className="mb-1 pl-2" {...props} />,
                strong: ({node, ...props}) => <strong className="font-bold" {...props} />,
                em: ({node, ...props}) => <em className="italic" {...props} />,
                blockquote: ({node, ...props}) => <blockquote className="border-l-4 border-gray-300 pl-4 italic my-4" {...props} />,
                code: ({node, inline, ...props}) => 
                    inline 
                    ? <code className="bg-gray-100 rounded px-1" {...props} />
                    : <pre className="bg-gray-100 rounded p-4 overflow-x-auto"><code {...props} /></pre>,
            }}
        >
        {content}
        </ReactMarkdown>
        </div>
        );
    };

export default MarkdownRenderer;
