import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import JobSearchPage from './pages/JobSearchPage';
import LoginPage from './pages/LoginPage';
import Header from './pages/headerPage';
import { getJobs } from './services/api';
import NotFound from './pages/NotFoundPage';
import UnderConstruction from './pages/buildingPage'
import ResumeBuilderPage from './pages/resumePage'
import PlanPage from './pages/planPage'
import UserSettingsPage from './pages/settingPage'
import JobDetailPage from './pages/JobDetailPage'
import Footer from './pages/footerPage'
import PaymentSuccessPage from './pages/paySuccess'
import { AuthProvider } from './pages/authProvider';
import LandingPage from './pages/landingPage';


function App() {
  const [jobs, setJobs] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const jobs = await getJobs();
        setJobs(jobs);
      } catch (error) {
        console.error('Failed to fetch jobs:', error);
      }
    };
    if (location.pathname === '/' || location.pathname === '/job/list') {
      fetchJobs();
    }
  }, []);
  

  const updateJobs = (newJobs) => {
    setJobs(newJobs);
  };

  const isLandingPage = location.pathname === '/dashboard';

// className="App flex flex-col min-h-screen bg-gradient-to-br from-blue-50 to-gray-200 pt-9 w-full"
  return (
    <AuthProvider>
    <div className="App flex flex-col min-h-screen bg-gradient-to-br from-green-50 to-blue-100 dark:from-green-900 dark:to-blue-800 pt-9 w-full">
      <Header setJobs={setJobs}/>
      <main className='flex-grow container mx-auto px-4 max-w-7xl'>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard" element={<LandingPage />} />
          <Route path="/" element={<JobSearchPage jobs={jobs} updateJobs={updateJobs} />} />
          <Route path="/job/:position" element={<JobSearchPage jobs={jobs} updateJobs={updateJobs} />} />
          <Route path="/job/remote/:uid" element={<JobDetailPage />} />
          <Route path="/plan" element={<PlanPage />} />
          <Route path="/pay/success" element={<PaymentSuccessPage />} />
          <Route path='/404' element={<NotFound />} />
          <Route path='*' element={<UnderConstruction setJobs={setJobs} />} />
        </Routes>
      </main>
      <Footer />
    </div>
    </AuthProvider>
  );
}

export default App;
