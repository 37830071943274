import axios from 'axios';
import { generateFromString } from 'generate-avatar'
// import { refreshToken, isTokenExpired, redirectToLogin } from '../controllers/refreshController';
// import { useNavigate } from 'react-router-dom';


const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:4567';
const api = axios.create({
  baseURL: API_URL,
});

export const isTokenExpired = async (token) => {
  const response = await api.post('/auth/is-token-expired', { token });
  return response.data.isExpired;
};

export const refreshTokenApi = async (token) => {
  const response = await api.post('/auth/refresh-token', { token });
  return response.data.newToken;
};


export const login = async (username, password) => {
  try {
    const response = await api.post('/auth/login', { username, password });
    localStorage.setItem('accessToken', response.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.user));
    const result = generateFromString(response.data.user.username)
    localStorage.setItem('avatar', result)
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);
    } else {
      throw new Error('Login failed, please try again later');
    }
  }
};

export const logout = async () => {
  const response = await api.post('/auth/logout');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('user');
  localStorage.removeItem('avatar');
  return response.data;
};

export const register = async (username, email, password) => {
  try {
    const response = await api.post('/auth/register', { username, email, password });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);
    } else {
      throw new Error('Registration failed, please try again later');
    }
  }
};

export const refresh = async () => {
  const response = await api.post('/auth/refresh');
  return response.data;
};


export const getJobs = async () => {
  const response = await api.get('/job/list?page=1&limit=20');
  return response.data;
};


export const getLocation = async () => {
  const response = await api.get('/job/location');
  return response.data;
}

export const getPositions = async () => {
  const response = await api.get('/job/position');
  return response.data;
}

export const fetchSources = async () => {
  try {
    const response = await api.get('/job/sources');
    return response.data;
  } catch (error) {
    console.error("Error in FetchSources", error);
    throw error;
  }
}

export const fetchJobs = async (searchTerm, locations, position, source, page = 1, limit = 20) => {
  try {
    const response = await api.get(`/job/search?searchTerm=${searchTerm}&locations=${locations}&position=${position}&source=${source}&page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error("Error in FetchJobs", error);
    throw error; 
  }
};

export const getDetail = async (uid) => {
  try {
    const response = await api.get(`/job/remote/${uid}`);
    return response.data;
  } catch (error) {
    console.error('Error in Get Detail:', error);
  }
}

export const createCheckoutSession = async (email, priceId, priceTitle) => {
  const response = await api.post('/pay/create-checkout-session', {
    email: email,
    priceId: priceId,
    priceTitle: priceTitle
  });
  return response.data; 
};

export const paymentCheckoutSuccess = async (sessionId, userId) => {
  const response = await api.get(`/pay/checkout-success?session_id=${sessionId}&userId=${userId}` );
  return response.data;
};

export const checkPlusStatus = async (userId) => {
  const response = await api.get(`/user/check-plus-status/${userId}`);
  return response.data.isPlusUser;
};

export const getKeywords = async (keyword) => {
  try {
    const response = await api.get(`/job/keywords?keyword=${keyword}`);
    return response.data;
  } catch (error) {
    console.error('Error in Get Keywords:', error);
  }
};


export const getKeywordsForIndex = async () => {
  try {
    const response = await api.get('/job/keywordsForIndex');
    return response.data;
  } catch (error) {
    console.error('Error in Get Keywords For Index:', error);
  }
}