import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom';
import { logout } from '../services/api';
import { useState, useEffect } from 'react';
import { getJobs } from '../services/api';
import { useAuth } from './authProvider';

const navigation = [
  { name: 'Dashboard', href: '/dashboard' },
  { name: 'Jobs', href: '/' }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header({setJobs}) {
    const navigate = useNavigate();
    const { isLoggedIn, isPlusUser, setIsLoggedIn } = useAuth();
    const [showTooltip, setShowTooltip] = useState(false);
    const user = localStorage.getItem('user');
    const username = user ? JSON.parse(user).username : null;
    const [ currentPage, setCurrentPage] = useState(() => {
      const path = window.location.pathname;
      return path === '/dashboard' ? 'Dashboard' : 'Jobs';
    });


    const handlePageChange = (name) => {
      setCurrentPage(name);
      if (name === 'Dashboard') {
        navigate('/dashboard');
      } else if (name === 'Jobs') {
        navigate('/');
      }
    }



    const fetchJobs = async () => {
      try {
        navigate('/');
        const jobs = await getJobs();
        setJobs(jobs);
        
      } catch (error) {
        console.error('Failed to fetch jobs:', error);
      }
    };
    

  return (
    <Disclosure as="nav" className="-mt-9">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button*/}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
              <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
            </DisclosureButton>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-end">
              <Logo />
              <span className="text-xl font-bold cursor-pointer" 
                onClick={(e) => {
                    e.stopPropagation();
                    fetchJobs();
                }}>RemoteSum</span>
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.name === currentPage ? 'text-black' : 'text-gray-500 hover:bg-gray-700 hover:text-white',
                      'rounded-md px-3 py-2 text-sm font-medium',
                    )}
                    onClick={() => handlePageChange(item.name)}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {isLoggedIn ? (
              <div 
                className="relative"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <LoginedAvatar isPlusUser={isPlusUser} setIsLoggedIn={setIsLoggedIn} />
                {showTooltip && (
                  <div className="absolute bottom-7 bg-white rounded-md shadow-xl z-20">
                    <p className="block px-2 text-sm text-gray-700">
                      {username || 'User'}
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <LoginButton />
            )}
          </div>
        </div>
      </div>

      <DisclosurePanel className="sm:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
          {navigation.map((item) => (
            <DisclosureButton
              key={item.name}
              as="a"
              href={item.href}
              className={classNames(
                item.name === currentPage ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'block rounded-md px-3 py-2 text-base font-medium',
              )}
              onClick={() => handlePageChange(item.name)}
            >
              {item.name}
            </DisclosureButton>
          ))}
        </div>
      </DisclosurePanel>
    </Disclosure>
  )
}


const Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" width="40" height="40">
      {/* 背景渐变 */}
      <defs>
        <linearGradient id="bg-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#5CDFBC" stopOpacity="1" />
          <stop offset="100%" stopColor="#57B9EF" stopOpacity="1" />
        </linearGradient>
      </defs>
      
      {/* 圆角矩形背景 */}
      <rect x="10" y="10" width="180" height="180" rx="20" ry="20" fill="url(#bg-gradient)"/>
      
      {/* SUM文字 */}
      <text x="100" y="130" fontFamily="Arial, sans-serif" fontSize="72" fontWeight="bold" fill="#ffffff" textAnchor="middle">SUM</text>
      
      {/* 装饰性线条 */}
      <path d="M 40 140 Q 100 160, 160 140" stroke="#ffffff" strokeWidth="6" fill="none" opacity="0.6"/>
      <path d="M 40 60 Q 100 40, 160 60" stroke="#ffffff" strokeWidth="6" fill="none" opacity="0.6"/>
    </svg>
  )
}


function LoginButton() {
    const navigate = useNavigate();
    return (
        <button className="bg-blue-500 text-white p-2 rounded-md" onClick={() => navigate('/login')}>
            Login
        </button>
    )
}


function LoginedAvatar({ isPlusUser, setIsLoggedIn }) {
    const handleLogout = async () => {
        try {
          await logout();
          localStorage.removeItem('accessToken');
          localStorage.removeItem('user');
          localStorage.removeItem('avatar');
          localStorage.removeItem('isPlusUser');
          setIsLoggedIn(false);
          console.log("you are logout");
        } catch (error) {
          console.error('Logout Failed:', error);
        }
      };
    
    return (
        <div>
            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-3">
              <div>
                <MenuButton className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">Open user menu</span>
                  <img src={`data:image/svg+xml;utf8,${localStorage.getItem('avatar')}`} alt="avatar" className="h-8 w-8 rounded-full object-cover"/>
                </MenuButton>
              </div>
              <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-38 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                {!isPlusUser && (<MenuItem>
                  <a href="/plan" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                    Upgrade
                  </a>
                </MenuItem>)}
                <MenuItem>
                  <a href="/" className="block px-4 py-3 text-sm text-gray-700 data-[focus]:bg-gray-100 whitespace-nowrap" onClick={() => handleLogout()}>
                    Sign out
                  </a>
                </MenuItem>
              </MenuItems>
            </Menu>
        </div>
    )
}

