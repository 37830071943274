import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import confetti from 'canvas-confetti';
import axios from 'axios'; // 确保已安装 axios
import { paymentCheckoutSuccess } from '../services/api';
import { useAuth } from './authProvider';

const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [countdown, setCountdown] = useState(10);
  const [paymentStatus, setPaymentStatus] = useState('checking');
  const { isPlusUser, setIsPlusUser } = useAuth();

  useEffect(() => {
    const timer = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    const redirectTimer = setTimeout(() => {
        navigate('/', { replace: true });
    }, 10000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
}, [paymentStatus]);


  useEffect(() => {
    const checkPaymentStatus = async () => {
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get('session_id');
      if (sessionId) {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            console.log("payment user: ", user);
            const response = await paymentCheckoutSuccess(sessionId, user.id);
            if (response.session.payment_status === 'paid') {
              setPaymentStatus('success');
              setIsPlusUser(true);
            } else {
              setPaymentStatus('failed');
            }
        } catch (error) {
          console.error('Error checking payment status:', error);
          setPaymentStatus('failed');
        }
      } else {
        setPaymentStatus('failed');
      }
    };

    checkPaymentStatus();

    const triggerConfetti = () => {
        const animDict = {
            particleCount: 1500,
            spread: 200,
            origin: { y: 0.6 },
            shapes: ['star', 'circle', 'square', 'triangle', 'heart'],
            shapesAnimation: {
                enable: true,
                speed: 40,
                decay: 0.1,
                sync: true,
            },
            decay: 0.95,
            scalar: 1.2
        }
        confetti(animDict);
        const multipleConfetti = () => {confetti(animDict)};
        const confettiInterval = setInterval(multipleConfetti, 3000);
        return confettiInterval;
    };

    const confettiInterval = paymentStatus === 'success' ? triggerConfetti() : null;

    return () => clearInterval(confettiInterval);
  }, [navigate, location, paymentStatus]);


  return (
    <div className="min-h-screen flex items-center justify-center px-4">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8 text-center">
        <h1 className={`text-3xl font-bold text-green-600 mb-4 
        ${paymentStatus === 'checking' ? 'text-gray-600' : 
          paymentStatus === 'success' ? 'text-green-600' : 'text-red-600'}`}>
          {paymentStatus === 'checking' ? 'Checking payment status...' :
           paymentStatus === 'success' ? 'Congratulations!' : 'Sorry'}
        </h1>
        <p className={`text-2xl font-bold text-green-400 mb-4 ${paymentStatus === 'success' ? 'text-green-400' : 'text-red-400'}`}>
          {paymentStatus === 'checking' ? null :
          paymentStatus === 'success' ? 'Payment successful' : 'Payment failed'}
        </p>
        <p className="text-lg text-gray-700 mb-6">
          {paymentStatus === 'checking' ? null :
          paymentStatus === 'success' ? 'You have successfully upgraded to Plus membership! Now you can access all remote job opportunities.' : 
          'Sorry, your payment could not be processed successfully.'}
        </p>
        <p className="text-lg text-gray-700 mb-6">
          {paymentStatus === 'checking' ? null :
          paymentStatus === 'success' ? 'Wish you find your dream remote job soon and start your remote work journey!' : 
          'Please try again later or contact customer support.'}
        </p>
        {paymentStatus === 'checking' ? null : <p className="text-gray-500 mb-4">{countdown} seconds to redirect to home page...</p>}
        <button
          onClick={() => navigate('/')}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          {paymentStatus === 'success' ? 'Start your remote work journey' : 'Return to home page'}
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
